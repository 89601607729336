body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/dm-mono/DMMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Mono";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/dm-mono/DMMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/dm-mono/DMMono-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/dm-sans/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/dm-sans/DMSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/dm-sans/DMSans-Medium.ttf") format("truetype");
}

button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 30px !important;
    line-height: 35px !important;
  }

  h2 {
    font-size: 20px !important;
    line-height: 24px !important;
    margin-bottom: 0 !important;
  }

  h3 {
    margin-bottom: 0 !important;
  }

  p {
    font-size: 14px !important;
    line-height: 19px !important;
    margin-top: 1em;
  }
}